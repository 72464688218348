@charset "UTF-8";
html.isDev._outline * {
  @apply outline-1 outline-lime-600 outline;
}

:root {
  --custom-bg: inherit;
  --custom-blason: inherit ;
}

html:has([data-layout=play]) {
  background-image: var(--custom-bg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 130%;
  background-color: inherit;
}
html:has([data-layout=play]) .dashboard-wrapper {
  background-color: transparent;
  background-image: var(--custom-blason), var(--custom-blason);
  background-repeat: no-repeat;
  background-size: 14vw;
  background-position: left center, right center;
}

html:has(.theme-controller.current[value=gryffondor]) {
  --custom-bg: url("https://images.ctfassets.net/usf1vwtuqyxm/65KXuf53D4H6qdUUHna3zB/7de5e7300f2a92023f782b10998acb8e/the-gryffindor-common-room_5_1800x1248.png?");
  --custom-blason: url("/images/custom/Blason_de_Gryffondor.webp") ;
}

html:has(.theme-controller.current[value=slytherin]) {
  --custom-bg: url("https://images.ctfassets.net/usf1vwtuqyxm/6YcisvFm3I6YZm4kzK3hMS/2b6bb132a87de3611e4b42048b756d8f/the-slytherin-common-room_4_1800x1248.png?fit=pad&fm=webp");
  --custom-blason: url("/images/custom/Blason_de_Serpentard.webp");
}

html:has(.theme-controller.current[value=ravenclaw]) {
  --custom-bg: url("https://images.ctfassets.net/usf1vwtuqyxm/1VEhY2hYfsBVXvnjC3kD0u/44fc853f78aad66546b23497b87f4d63/the-ravenclaw-common-room_2_1800x1248.png?&fit=pad&fm=webp");
  --custom-blason: url("/images/custom/Blason_de_Serdaigle.webp");
}

html:has(.theme-controller.current[value=hufflepuff]) {
  --custom-bg: url("https://images.ctfassets.net/usf1vwtuqyxm/7KzzNMFOrRUu6yiqwsbwv7/bebfd297deead4c73c32896974f36f76/the-hufflepuff-common-room_2_1800x1248.png?&fit=pad&fm=webp");
  --custom-blason: url("/images/custom/Blason_de_Poufsouffle.webp");
}

html:has(.theme-controller.current[value=hobbit]) {
  --custom-bg: url("/images/custom/hobbit.jpg");
}

html:has(.game-27) {
  background-image: url("/images/custom/fond-hp.webp");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

html:has(.game-69) {
  background-image: url("/images/custom/la-comte.webp");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

form.Loot .tagify__input {
  background-color: inherit;
  min-width: 100%;
}
form.Loot .tagify__input:focus {
  outline: 2px solid hsla(var(--bc)/0.2);
  outline-offset: 2px;
  border-radius: var(--rounded-btn, 0.5rem);
}

#players {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  grid-gap: 11px;
  grid-auto-rows: minmax(35px, auto);
  font-size: 13px;
  word-break: break-word;
  margin-top: 0px;
  padding: 15px 0;
  align-items: start;
}
#players > div.__none {
  background-color: var(--blink-bg) !important;
}
#players > div.dead {
  opacity: 0.5;
  position: relative;
  transition: opacity 0.5s;
  cursor: not-allowed;
}
#players > div.dead:hover {
  opacity: 1;
}
#players > div.dead:hover::after {
  opacity: 0.1;
}
#players > div.dead::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
#players > div.dead::after {
  content: "💀";
  opacity: 1;
  font-size: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
#players > div > h6 {
  overflow: hidden;
}

.tab.tab-PollForm .indicator-item {
  --tw-translate-x: 25%;
  --tw-translate-y: -25%;
}
.tab.tab-PollForm.tab-active .indicator {
  color: inherit;
}
.tab.tab-PollForm.tab-active .indicator .indicator-item {
  display: none;
}

.input-group .tagForm .tagify__tag-text {
  background-color: inherit !important;
}

.tagify {
  --tag-bg: #3597f9 !important;
  width: 100%;
  border: 0 !important;
}
.tagify[readonly] {
  --readonly-striped: 0;
  --tag-hover: var(--tag-bg);
  --tag-bg-inset: 0;
  pointer-events: none;
}
.tagify:not([readonly]) {
  flex-basis: 100;
}
.tagify .tagify__tag span {
  background-color: inherit;
}

.tagForm .taglist-line {
  background-color: var(--fallbackb1, oklch(var(-b1)/var(--tw-bg-opacity)));
}
.tagForm .tags-input {
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
}
.tagForm .tags-input .tagify__input {
  background-color: inherit;
  flex-grow: 1000;
  border-bottom: 1px solid grey;
  border-left: 1px solid grey;
}
.tagForm .tags-input .tagify__tag {
  flex-grow: 1;
}

.scope-selector .tagify__input {
  background-color: inherit;
}

.tagify__tag[negate=true] {
  --tag-bg: var(--fallback-er, oklch(var(--er)/var(--tw-bg-opacity)));
}

html.dark .tagify__dropdown {
  color: black !important;
}
html.dark .tagify__dropdown .tagify__dropdown__item::first-letter {
  text-transform: uppercase;
}

@keyframes stick-gauges {
  from {
    position: initial;
  }
  to {
    position: sticky;
  }
}
/* Too avoid fontawesome "loading pumping effect" */
.svg-inline--fa {
  height: 1em;
}

.bg-blink {
  animation-duration: 0.6s;
  animation-name: bg-blink;
  animation-iteration-count: 4;
}

.blink {
  animation-duration: 0.6s;
  animation-name: blink;
  animation-iteration-count: 4;
}

.tooltip:before, .tooltip:after {
  @apply z-[11];
}
.tooltip:before {
  @apply whitespace-pre-wrap;
}

@keyframes bg-blink {
  0% {
    background: transparent;
  }
  40% {
    background: var(--blink-bg);
  }
  100% {
    background: transparent;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}